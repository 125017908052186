import http from '../http-common'
import { APIBaseFunctions, APIDataSliceObj, defaultAPILimit } from '@/components/Utility/APIBase'

class UsersDataService extends APIBaseFunctions {
  private cachedUsersDataSliceObj: APIDataSliceObj = {
    slicemode: 0,
    start: 0,
    limit: defaultAPILimit,
    page: 0,
    pagesize: 0,
    totalcount: 0
  }

  getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
    const currentDataSliceObj = UsersDataService.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedUsersDataSliceObj }, dataSliceObj)

    if (sortingOrder.length > 0) {
      return http.get(`/users?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    return http.get(`/users?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
  }

  async asyncGetAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
    const currentDataSliceObj = UsersDataService.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedUsersDataSliceObj }, dataSliceObj)

    if (sortingOrder.length > 0) {
      return http.get(`/users?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    return http.get(`/users?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
  }

  get (id: string) {
    return http.get(`/users/${id}`)
  }

  getCount (extraCountParameter = '') {
    return http.get('/users/count' + (extraCountParameter.length > 0 ? `?${extraCountParameter}` : ''))
  }

  create (data: any) {
    return http.post('/users', data)
  }

  async asyncCreate (data: any) {
    return http.post('/auth/local/register', data)
  }

  update (id: string, data: any) {
    return http.put(`/users/${id}`, data)
  }

  delete (id: string) {
    return http.delete(`/users/${id}`)
  }

  deleteAll () {
    return http.delete('/users')
  }

  findByUserStatus (status: boolean, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
    let sortingOrderParameter = ''
    const currentDataSliceObj = UsersDataService.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedUsersDataSliceObj }, dataSliceObj)

    if (sortingOrder.length > 0) {
      sortingOrderParameter = `&_sort=${sortingOrder}`
    }

    return http.get(`/users?blocked=${!status}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
  }

  findBySearchTerm (status: boolean, ignoreStatus: boolean, searchTerm: string, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
    let retVal
    let sortingOrderParameter = ''
    const currentDataSliceObj = UsersDataService.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedUsersDataSliceObj }, dataSliceObj)
    searchTerm = searchTerm.trim()

    if (sortingOrder.length > 0) {
      sortingOrderParameter = `&_sort=${sortingOrder}`
    }

    if (!isNaN(Number(searchTerm))) {
      // Handle telephone number in search term
      if (ignoreStatus) {
        // retVal = http.get(`/brugers?bruger_telefon_contains=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
        retVal = http.get(`/users?phone_contains=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      } else {
        // retVal = http.get(`/brugers?blocked=${!status}&bruger_telefon_contains=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
        retVal = http.get(`/users?blocked=${!status}&phone_contains=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }
    } else if (searchTerm.indexOf('@') >= 0) {
      // Handle email address in search term
      if (ignoreStatus) {
        // retVal = http.get(`/brugers?bruger_email_contains=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
        retVal = http.get(`/users?email_contains=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      } else {
        // retVal = http.get(`/brugers?blocked=${!status}&bruger_email_contains=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
        retVal = http.get(`/users?blocked=${!status}&email_contains=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }
    } else {
      const searchTermArray = searchTerm.trim().split(' ')

      if (searchTermArray.length > 1) {
        // Do a special search in names for the search term where the first part of the of the search term that is seperated with a space is considered as the first name, and the last part of the search term, that is seperated with a space is considered as the last name
        if (ignoreStatus) {
          // retVal = http.get(`/brugers?_where[_or][0][bruger_brugernavn_contains]=${searchTerm}&_where[_or][1][bruger_fornavn_contains]=${searchTerm}&_where[_or][2][bruger_efternavn_contains]=${searchTerm}&_where[_or][3][bruger_fornavn_contains]=${searchTermArray[0]}&_where[_or][4][bruger_efternavn_contains]=${searchTermArray[searchTermArray.length - 1]}${sortingOrderParameter}`)
          // retVal = http.get(`/brugers?_where[_or][0][bruger_brugernavn_contains]=${searchTerm}&_where[_or][1][bruger_fornavn_contains]=${searchTerm}&_where[_or][2][bruger_efternavn_contains]=${searchTerm}&_where[_or][3][0][bruger_fornavn_contains]=${searchTermArray[0]}&_where[_or][3][1][bruger_efternavn_contains]=${searchTermArray[searchTermArray.length - 1]}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
          retVal = http.get(`/users?_where[_or][0][username_contains]=${searchTerm}&_where[_or][1][firstname_contains]=${searchTerm}&_where[_or][2][lastname_contains]=${searchTerm}&_where[_or][3][0][firstname_contains]=${searchTermArray[0]}&_where[_or][3][1][lastname_contains]=${searchTermArray[searchTermArray.length - 1]}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
        } else {
          // retVal = http.get(`/brugers?blocked=${!status}&_where[_or][0][bruger_brugernavn_contains]=${searchTerm}&_where[_or][1][bruger_fornavn_contains]=${searchTerm}&_where[_or][2][bruger_efternavn_contains]=${searchTerm}&_where[_or][3][bruger_fornavn_contains]=${searchTermArray[0]}&_where[_or][4][bruger_efternavn_contains]=${searchTermArray[searchTermArray.length - 1]}${sortingOrderParameter}`)
          // retVal = http.get(`/brugers?blocked=${!status}&_where[_or][0][bruger_brugernavn_contains]=${searchTerm}&_where[_or][1][bruger_fornavn_contains]=${searchTerm}&_where[_or][2][bruger_efternavn_contains]=${searchTerm}&_where[_or][3][0][bruger_fornavn_contains]=${searchTermArray[0]}&_where[_or][3][1][bruger_efternavn_contains]=${searchTermArray[searchTermArray.length - 1]}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
          retVal = http.get(`/users?blocked=${!status}&_where[_or][0][username_contains]=${searchTerm}&_where[_or][1][firstname_contains]=${searchTerm}&_where[_or][2][lastname_contains]=${searchTerm}&_where[_or][3][0][firstname_contains]=${searchTermArray[0]}&_where[_or][3][1][lastname_contains]=${searchTermArray[searchTermArray.length - 1]}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
        }
      } else {
        // Do a normal search in names for the search term
        if (ignoreStatus) {
          // retVal = http.get(`/brugers?_where[_or][0][bruger_brugernavn_contains]=${searchTerm}&_where[_or][1][bruger_fornavn_contains]=${searchTerm}&_where[_or][2][bruger_efternavn_contains]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
          retVal = http.get(`/users?_where[_or][0][username_contains]=${searchTerm}&_where[_or][1][firstname_contains]=${searchTerm}&_where[_or][2][lastname_contains]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
        } else {
          // retVal = http.get(`/brugers?blocked=${!status}&_where[_or][0][bruger_brugernavn_contains]=${searchTerm}&_where[_or][1][bruger_fornavn_contains]=${searchTerm}&_where[_or][2][bruger_efternavn_contains]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
          retVal = http.get(`/users?blocked=${!status}&_where[_or][0][username_contains]=${searchTerm}&_where[_or][1][firstname_contains]=${searchTerm}&_where[_or][2][lastname_contains]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
        }
      }
    }

    return retVal
  }
}

export default new UsersDataService()
